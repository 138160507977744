import { useMutation, useQueryClient } from "react-query";
import supabase from "../../supabase";

export async function updateLines(lines) {
  const resultUpdate = await supabase.from("quote_lines").upsert(lines);

  // const resultInsert = await supabase.from("quote_lines").insert(linesForCreate);

  const data = { ...resultUpdate.data };

  return data;
}

export const useUpdateLines = () => {
  const queryClient = useQueryClient();
  return useMutation((lines) => updateLines(lines), {
    onSuccess: () => {
      queryClient.refetchQueries("quotes");
    },
    onError: (error) => error,
    mutationFn: updateLines,
  });
};
