import { useMutation, useQueryClient } from "react-query";
import supabase from "../../supabase";

export async function updateRates(rates) {
  const { data, error } = await supabase.from("quote_rates").upsert(rates);
  if (error) {
    throw new Error(error.message);
  }

  return data;
}

export const useUpdateRates = () => {
  const queryClient = useQueryClient();
  return useMutation((rates) => updateRates(rates), {
    onSuccess: () => {
      queryClient.refetchQueries("quotes");
    },
    onError: (error) => error,
    mutationFn: updateRates,
  });
};
