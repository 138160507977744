import { useMutation, useQueryClient } from "react-query";
import supabase from "../../supabase";

export async function updateZones(zones) {
  const { data, error } = await supabase.from("quote_zones").upsert(zones);
  if (error) {
    throw new Error(error.message);
  }

  return data;
}

export const useUpdateZones = () => {
  const queryClient = useQueryClient();
  return useMutation((quote) => updateZones(quote), {
    onSuccess: () => {
      queryClient.refetchQueries("quotes");
    },
    onError: (error) => error,
    mutationFn: updateZones,
  });
};
