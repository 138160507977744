import { useMutation, useQueryClient } from "react-query";
import supabase from "../../supabase";

export async function updateAdditionalConditions(additionalConditions) {
  const { data, error } = await supabase
    .from("quote_additional_conditions")
    .upsert(additionalConditions);

  if (error) {
    throw new Error(error.message);
  }

  return data;
}

export const useUpdateAdditionalConditions = () => {
  const queryClient = useQueryClient();
  return useMutation((additionalConditions) => updateAdditionalConditions(additionalConditions), {
    onSuccess: () => {
      queryClient.refetchQueries("quotes");
    },
    onError: (error) => error,
    mutationFn: updateAdditionalConditions,
  });
};
